// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/FormWrapper.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/FormWrapper.jsx");
  import.meta.hot.lastModified = "1720660792816.505";
}
// REMIX HMR END

import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
const FrormWrapper = ({
  blok
}) => <div {...storyblokEditable(blok)} key={blok._uid} className="flex justify-center p-12">
  <form name="contact" method="POST" data-netlify="true" action={`/${blok.navigationSuccess.cached_url}`} className="flex flex-col w-[400px]" {...storyblokEditable(blok)}>
  {blok.forms.map(nestedBlok => <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />)}
      <button type="submit" className="w-full rounded-[8px] bg-primary px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out bg-[#00b3b0] hover:bg-[#009f9c] hover:shadow-lg focus:bg-[#009f9c] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#009f9c] active:shadow-lg">Send</button>
  </form>
</div>;
_c = FrormWrapper;
export default FrormWrapper;
var _c;
$RefreshReg$(_c, "FrormWrapper");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;