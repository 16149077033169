// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Grid.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Grid.jsx");
  import.meta.hot.lastModified = "1720669257059.5042";
}
// REMIX HMR END

import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
const Grid = ({
  blok
}) => {
  //console.log(blok);
  return <ul {...storyblokEditable(blok)} key={blok._uid} className="container mx-auto grid md:grid-cols-3 gap-12 my-12 place-items-center">
    {blok.columns.map(blok => <li key={blok._uid}>
        <StoryblokComponent blok={blok} />
      </li>)}
  </ul>;
};
_c = Grid;
export default Grid;
var _c;
$RefreshReg$(_c, "Grid");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;