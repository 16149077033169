// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/FormCheckbox.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/FormCheckbox.jsx");
  import.meta.hot.lastModified = "1720660792812.505";
}
// REMIX HMR END

import { storyblokEditable } from "@storyblok/react";
const FormCheckbox = ({
  blok
}) => {
  return <div className="form-group mb-6 flex" {...storyblokEditable(blok)}>
		<input type='checkbox' name={blok.name} className="mr-4" id={blok.name} required={blok.required} />
        <label htmlFor={blok.name}>{blok.label}</label>
	</div>;
};
_c = FormCheckbox;
export default FormCheckbox;
var _c;
$RefreshReg$(_c, "FormCheckbox");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;