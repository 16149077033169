// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Page.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Page.jsx");
  import.meta.hot.lastModified = "1720749894362.5051";
}
// REMIX HMR END

import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import React from "react";
const Page = ({
  blok
}) => {
  _s();
  const [showGrid, setShowGrid] = React.useState(true);
  let label = {
    show: '',
    hide: '',
    classname: ''
  };
  //console.log(blok); 
  return <main {...storyblokEditable(blok)} key={blok._uid} className="px-4">
    {blok.body.map(nestedBlok => {
      if (nestedBlok.component == 'label') {
        label = {
          show: nestedBlok.show,
          hide: nestedBlok.hide,
          classname: nestedBlok.classname
        };
        return '';
      }
      if (nestedBlok.component == 'grid_condition') {
        if (showGrid) {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />;
        }
      } else {
        return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />;
      }
    })}
    <center>
    <button type="button" className={label.classname} onClick={() => setShowGrid(!showGrid)}>{showGrid ? label.hide : label.show}</button>
      </center>
  </main>;
};
_s(Page, "f25+F09y8fFgKMmYE52mvAQyFsc=");
_c = Page;
export default Page;
var _c;
$RefreshReg$(_c, "Page");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;